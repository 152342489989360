<script lang="ts" setup>
const { $gsap } = useNuxtApp();

const animate = () => {
  const trackingSelector = $gsap.utils.selector('#tracking');

  const trackingTimeline1 = $gsap.timeline({
    scrollTrigger: {
      trigger: trackingSelector('.content-1'),
      start: 'top 65%',
      end: 'top 35%',
      scrub: 1.5,
      once: true,
      snap: {
        snapTo: 'labels',
        duration: { min: 3, max: 5 },
        delay: 0.5,
      },
    },
  });

  trackingTimeline1.fromTo(
    trackingSelector('.content-1 h4'),
    { opacity: 0, x: -10 },
    {
      opacity: 1,
      x: 0,
    },
    'content-1',
  );
  trackingTimeline1.fromTo(trackingSelector('.content-1 h3'), { opacity: 0, x: -10 }, { opacity: 1, x: 0 });
  trackingTimeline1.fromTo(trackingSelector('.content-1 p'), { opacity: 0, x: -10 }, { opacity: 1, x: 0 });
  trackingTimeline1.fromTo(trackingSelector('.content-1 .coming-soon'), { opacity: 0, x: -10 }, { opacity: 1, x: 0 });

  trackingTimeline1.fromTo(trackingSelector('.image-1 .h-line'), { x: '-100%' }, { x: 0 }, 'image-1');
  trackingTimeline1.fromTo(trackingSelector('.image-1 .circle'), { scale: 0.7, opacity: 0 }, { scale: 1, opacity: 1 });
  trackingTimeline1.fromTo(trackingSelector('.image-1 .bg-grad'), { scale: 1, opacity: 0 }, { scale: 2.2, opacity: 1 });
  trackingTimeline1.fromTo(trackingSelector('.image-1 .circle img'), { opacity: 0 }, { opacity: 1 });

  const trackingTimeline2 = $gsap.timeline({
    scrollTrigger: {
      trigger: trackingSelector('.content-2'),
      start: 'top 75%',
      end: 'top 45%',
      scrub: 1.5,
      once: true,
      snap: {
        snapTo: 'labels',
        duration: { min: 3, max: 5 },
        delay: 0.2,
        ease: 'power1.inOut',
      },
    },
  });

  trackingTimeline2.fromTo(
    trackingSelector('.content-2 h4'),
    { opacity: 0, x: -10 },
    {
      opacity: 1,
      x: 0,
    },
    'content-2',
  );
  trackingTimeline2.fromTo(trackingSelector('.content-2 h3'), { opacity: 0, x: -10 }, { opacity: 1, x: 0 }, '<0.2');
  trackingTimeline2.fromTo(trackingSelector('.content-2 p'), { opacity: 0, x: -10 }, { opacity: 1, x: 0 }, '<0.2');
  trackingTimeline2.fromTo(
    trackingSelector('.content-2 .coming-soon'),
    { opacity: 0, x: -10 },
    {
      opacity: 1,
      x: 0,
    },
    '<0.2',
  );

  trackingTimeline2.fromTo(trackingSelector('.image-2 .h-line'), { x: '100%' }, { x: 0 }, 'image-2');
  trackingTimeline2.fromTo(
    trackingSelector('.image-2 .circle'),
    { scale: 0.7, opacity: 0 },
    {
      scale: 1,
      opacity: 1,
    },
    '<0.25',
  );
  trackingTimeline2.fromTo(
    trackingSelector('.image-2 .bg-grad'),
    { scale: 1, opacity: 0 },
    {
      scale: 2.2,
      opacity: 1,
    },
    '<0.1',
  );
  trackingTimeline2.fromTo(trackingSelector('.image-2 .circle img'), { opacity: 0 }, { opacity: 1 }, '<0.3');
};

onMounted(() => {
  animate();
});

onUnmounted(() => {
  $gsap.killTweensOf('#tracking *');
});
</script>

<template>
  <div class="mb-4">
    <div id="tracking" class="md:grid md:grid-rows-2 pt-[100px] md:pt-[150px] max-w-4xl mx-auto px-4">
      <div class="md:col-start-1 md:col-span-1 md:row-start-1 md:row-span-1 md:pr-16 pb-16 bg-white z-20">
        <div class="content-1 flex flex-col gap-8 text-center md:text-left">
          <header>
            <UIHeading
              customClasses="opacity-0 uppercase text-secondary-700 border-b border-secondary-700 pb-2 w-fit mb-5 mx-auto md:mx-0"
              size="h4"
              styleSize="h6"
            >
              {{ $t('landing.pathwaysMap') }}
            </UIHeading>
            <UIHeading customClasses="opacity-0" size="h3" styleSize="h3"> {{ $t('landing.feelingUnsure') }}</UIHeading>
          </header>
          <p class="opacity-0">
            {{ $t('landing.discoverPathwaysMap') }}
          </p>
          <div
            class="coming-soon uppercase opacity-0 mx-auto md:mx-0 bg-gradient-to-r from-[#FA06FF] to-[#6260F7] text-white rounded w-fit px-3 py-1 text-xl font-semibold"
          >
            {{ $t('landing.comingSoon') }}
          </div>
        </div>
      </div>
      <div class="image-1 md:col-start-3 md:col-span-1 md:row-start-1 md:row-end-2">
        <div class="flex items-center justify-center md:justify-start h-full">
          <div class="hidden md:block h-line h-px w-24 bg-secondary-500 relative z-10 -translate-x-full" />
          <div class="grid z-20">
            <div
              class="bg-grad opacity-0 col-start-1 row-start-1 z-10 bg-[url('/images/landing/tracking-gradient.svg')] bg-cover scale-[1] w-60 h-60 -translate-y-6"
            />
            <div
              class="relative circle opacity-0 col-start-1 row-start-1 z-20 flex justify-center items-end rounded-full aspect-square bg-primary-700 w-60 h-60"
            >
              <div class="stars-layer-1" />
              <NuxtImg class="w-full opacity-0" role="presentation" src="/images/landing/tracks-phone.webp" />
            </div>
          </div>
        </div>
      </div>
      <div class="hidden md:block md:col-start-2 md:col-span-1 md:row-start-1 md:-row-end-1 bg-secondary-500 -mt-[200px]" />
      <div class="md:col-start-3 md:col-span-1 md:row-start-2 md:row-span-1 mt-20 md:pl-16 pb-16 bg-white z-20">
        <div class="content-2 flex flex-col gap-8 text-center md:text-left">
          <header>
            <UIHeading
              customClasses="opacity-0 uppercase text-secondary-700 border-b border-secondary-700 pb-2 w-fit mb-5 mx-auto md:mx-0"
              size="h4"
              styleSize="h6"
            >
              {{ $t('landing.actionPlan') }}
            </UIHeading>
            <UIHeading customClasses="opacity-0" size="h3" styleSize="h3">{{ $t('landing.letCareerCoachGuideYou') }} </UIHeading>
          </header>
          <p class="opacity-0">
            {{ $t('landing.weMakeActionPlans') }}
          </p>
          <div
            class="coming-soon uppercase opacity-0 mx-auto md:mx-0 bg-gradient-to-r from-[#FA06FF] to-[#6260F7] text-white rounded w-fit px-3 py-1 text-xl font-semibold"
          >
            {{ $t('landing.comingSoon') }}
          </div>
        </div>
      </div>
      <div class="image-2 mt-12 md:mt-0 md:col-start-1 md:col-span-1 md:row-start-2 md:row-end-3">
        <div class="flex justify-center md:justify-end items-center h-full">
          <div class="grid">
            <div
              class="bg-grad opacity-0 col-start-1 row-start-1 z-10 bg-[url('/images/landing/tracking-gradient.svg')] bg-cover scale-[2] w-60 h-60 translate-y-4"
            />
            <div
              class="relative circle opacity-0 col-start-1 row-start-1 z-20 flex justify-center items-end rounded-full aspect-square bg-primary-700 w-60 h-60"
            >
              <div class="stars-layer-1" />
              <NuxtImg class="w-full opacity-0" role="presentation" src="/images/landing/action-plan/action-plan-phone.webp" />
            </div>
          </div>
          <div class="hidden md:block h-line h-px w-32 bg-secondary-500 relative z-10 translate-x-full" />
        </div>
      </div>
    </div>
    <div class="flex justify-center z-10 my-10">
      <UIButton
        :text="$t('landing.getStartedNowItsFree')"
        :to="{ name: 'plan-login' }"
        class=""
        customClasses="text-lg md:text-3xl hover:scale-110 transition-transform duration-500 shadow-xl w-fit"
        iconPath="circle-right-arrow"
        iconPosition="right"
        size="lg"
        variant="gradient"
      />
    </div>
  </div>
</template>

<style scoped>
.stars-layer-1 {
  position: absolute;
  background-image: url('/images/stars.svg');
  background-repeat: repeat;
  display: block;
  height: 100%;
  width: 100%;
  background-size: 300%;
  background-position: 55px 100px;
}

#tracking {
  grid-template-columns: 1fr 1px 1fr;
}
</style>
